export const PATHS = {
  'about': '/about-us/',
  'contact': '#contact',
  'services': '/#services',
  'marketing': '/marketing/',
  'development': '/marketing/#development',
  'design': '/marketing/#design',
  'sem': '/marketing/#search-engine-marketing',
  'drupal': '/marketing/#drupal',
  'media': '/media/',
  'photography': '/media/#photography',
  'video': '/media/#videography',
  'social': '/media/#social',
  'hulu': '/media/hulu/',
  'it': '/information-technology/',
  'commercialIT': '/information-technology/#commercial',
  'residentialIT': '/information-technology/#residential',
  'security': '/information-technology/#security',
  'aerial': '/aerial/',
  'inspections': '/aerial/#inspections',
  'aerialPhotography': '/aerial/#photography',
  'realEstate': '/aerial/#real-estate',
  'firstResponders': '/aerial/#first-responders',
  'team': '/team/',
  'blog': '/blogs/',
  'project': '/projects/',
  'legal': '/legal/',
}

export const SOCIAL = {
  'facebook': 'https://fb.me/www.JLTech',
  'instagram': 'https://www.instagram.com/jlte.ch',
  'twitter': 'https://www.twitter.com/JLTe_ch',
  'patreon': 'https://www.patreon.com/jltech_',
}

export const BREAKPOINTS = {
  'sm': 576,
  'md': 768,
  'lg': 992,
  'xl': 1200,
  'xxl': 1400,
  '4k': 3000,
}

import styled, {css} from "styled-components";
import {BREAKPOINTS} from "../constants";
import {useMediaQuery} from 'react-responsive';
import {Modal as AntdModal} from "antd";

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: BREAKPOINTS['lg'] });
  return isDesktop ? children : null;
}

export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: BREAKPOINTS['md'], maxWidth: BREAKPOINTS['lg'] })
  return isTablet ? children : null
}

export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINTS['md'] })
  return isMobile ? children : null
}

// Container defaults
export const CONTAINER = css`
  margin: 0 auto;
  padding: 0 40px;
  width: 100%;
  max-width: 100%;

  @media (min-width: ${BREAKPOINTS['sm']}px) {
    max-width: 540px;
  }

  @media (min-width: ${BREAKPOINTS['md']}px) {
    max-width: 720px;
  }
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    max-width: 960px;
  }

  @media (min-width: ${BREAKPOINTS['xl']}px) {
    max-width: 1140px;
  }

  @media (min-width: ${BREAKPOINTS['xxl']}px) {
    max-width: 1320px;
  }

  @media (min-width: ${BREAKPOINTS['4k']}px) {
    max-width: 2640px;
  }
`;


// Display text 1
export const D1 = css`
  line-height: 100%;
  font-size: 72px;
  font-size: 18vw;
  
  @media (min-width: ${BREAKPOINTS['sm']}px) {
    font-size: 14vw;
  }

  @media (min-width: ${BREAKPOINTS['md']}px) {
    font-size: 13vw;
  }
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    font-size: 12vw;
  }

  @media (min-width: ${BREAKPOINTS['xl']}px) {
    font-size: 11vw;
  }

  @media (min-width: ${BREAKPOINTS['xxl']}px) {
    font-size: 9vw;
  }

  @media (min-width: ${BREAKPOINTS['4k']}px) {
    font-size: 9vw;
  }
`;

export const H1 = css`
  line-height: 100%;
  font-size: 80px;
  font-family: var(--unnamed-font-family-brandon-grotesque);

  @media (min-width: ${BREAKPOINTS['4k']}px) {
    font-size: 5vw;
  }
`;

export const H2 = css`
  line-height: 100%;
  font-size: 60px;
  font-family: var(--unnamed-font-family-brandon-grotesque);

  @media (min-width: ${BREAKPOINTS['4k']}px) {
    font-size: 4vw;
  }
`;

export const H3 = css`
  line-height: 100%;
  font-size: 45px;
  font-family: var(--unnamed-font-family-brandon-grotesque);

  @media (min-width: ${BREAKPOINTS['4k']}px) {
    font-size: 3vw;
  }
`;

export const H4 = css`
  line-height: 100%;
  font-size: 30px;
  font-family: var(--unnamed-font-family-brandon-grotesque);

  @media (min-width: ${BREAKPOINTS['4k']}px) {
    font-size: 2vw;
  }
`;

export const H5 = css`
  line-height: 100%;
  font-size: 24px;
  font-family: var(--unnamed-font-family-brandon-grotesque);

  @media (min-width: ${BREAKPOINTS['4k']}px) {
    font-size: 1.5vw;
  }
`;

export const H6 = css`
  line-height: 100%;
  font-size: 18px;
  font-family: var(--unnamed-font-family-brandon-grotesque);

  @media (min-width: ${BREAKPOINTS['4k']}px) {
    font-size: 1vw;
  }
`;

export const BUTTONTEXT = css`
  line-height: 100%;
  font-size: 24px;
  font-family: var(--unnamed-font-family-brandon-grotesque);
  
  @media (min-width: ${BREAKPOINTS['sm']}px) {
    font-size: 24px;
  }

  @media (min-width: ${BREAKPOINTS['md']}px) {
    font-size: 24px;
  }
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    font-size: 24px;
  }

  @media (min-width: ${BREAKPOINTS['xl']}px) {
    font-size: 24px;
  }

  @media (min-width: ${BREAKPOINTS['xxl']}px) {
    font-size: 24px;
  }

  @media (min-width: ${BREAKPOINTS['4k']}px) {
    font-size: 1.5vw;
  }
`;

export const LEADTEXT = css`
  line-height: 110%;
  font-size: 18px;
  font-family: var(--unnamed-font-family-proxima-nova);
  
  @media (min-width: ${BREAKPOINTS['sm']}px) {
    font-size: 18px;
  }

  @media (min-width: ${BREAKPOINTS['md']}px) {
    font-size: 18px;
  }
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    font-size: 18px;
  }

  @media (min-width: ${BREAKPOINTS['xl']}px) {
    font-size: 18px;
  }

  @media (min-width: ${BREAKPOINTS['xxl']}px) {
    font-size: 18px;
  }

  @media (min-width: ${BREAKPOINTS['4k']}px) {
    font-size: 1vw;
  }
`;

export const PARAGRAPH = css`
  line-height: 100%;
  font-size: 16px;
  font-family: var(--unnamed-font-family-proxima-nova);
  
  @media (min-width: ${BREAKPOINTS['sm']}px) {
    font-size: 16px;
  }

  @media (min-width: ${BREAKPOINTS['md']}px) {
    font-size: 16px;
  }
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    font-size: 16px;
  }

  @media (min-width: ${BREAKPOINTS['xl']}px) {
    font-size: 16px;
  }

  @media (min-width: ${BREAKPOINTS['xxl']}px) {
    font-size: 16px;
  }

  @media (min-width: ${BREAKPOINTS['4k']}px) {
    font-size: 1vw;
  }
`;

export const SMALL = css`
  line-height: 100%;
  font-size: 12px;
  font-family: var(--unnamed-font-family-proxima-nova);
  
  @media (min-width: ${BREAKPOINTS['sm']}px) {
    font-size: 12px;
  }

  @media (min-width: ${BREAKPOINTS['md']}px) {
    font-size: 12px;
  }
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    font-size: 12px;
  }

  @media (min-width: ${BREAKPOINTS['xl']}px) {
    font-size: 12px;
  }

  @media (min-width: ${BREAKPOINTS['xxl']}px) {
    font-size: 12px;
  }

  @media (min-width: ${BREAKPOINTS['4k']}px) {
    font-size: 0.75vw;
  }
`;

export const Modal = styled(AntdModal)`
  width: 100%;
  
  .ant-modal-content {
    padding: 50px 35px;
  }
  
  .ant-modal-header {
    border: 0;
    padding: 0;
  }
  
  .ant-modal-close {
    top: 50px;
    right: 35px;
    
    .ant-modal-close-x {
      height: auto;
      width: auto;
      line-height: 100%;
      font-size: 40px;
      color: rgba(255, 61, 0, 1);
    }
  }
  
  .ant-modal-body {
    padding: 0;
  }
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    max-width: 80%;
    height: auto:
    top: 100px;
    
    .ant-modal-content {
      padding: 50px 100px;
    }
    
    .ant-modal-body {
      padding: 50px 0;
    }
  }
`;

export const LogoWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 140px;
  margin-bottom: 100px;
  
  @media (min-width: ${BREAKPOINTS['sm']}px) {
    max-width: 250px;
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    max-width: 10vw;
  }
`;

export const ImageLeft = styled.div`
  @media (min-width: ${BREAKPOINTS['md']}px) {
    position: absolute;
    width: 40vw;
    right: 100px;
  }
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    right: 120px;
    top: -30vh;
  }
  
  @media (min-width: ${BREAKPOINTS['xl']}px) {
    right: 150px;
    top: -30vh;
  }
  
  @media (min-width: ${BREAKPOINTS['xxl']}px) {
    right: 300px;
    top: -30vh;
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    top: -30vh;
    right: 380px;
  }
`;

export const ImageRight = styled.div`
  @media (min-width: ${BREAKPOINTS['md']}px) {
    position: absolute;
    width: 40vw;
    left: 100px;
  }
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    left: 120px;
    top: -30vh;
  }
  
  @media (min-width: ${BREAKPOINTS['xl']}px) {
    left: 150px;
    top: -30vh;
  }
  
  @media (min-width: ${BREAKPOINTS['xxl']}px) {
    left: 300px;
    top: -30vh;
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    top: -30vh;
    left: 380px;
  }
`;
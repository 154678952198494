import React from "react";
import styled from "styled-components";

const Underlay = ({children, ...props}) => {
  return (
    <Wrapper {...props}>
      {children}
    </Wrapper>
  );
};

export default Underlay;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 1;
  z-index: -1;
  
  @media (max-width: 768px) {
    height: auto;
  }
`
import React, {useState} from "react";
import PropTypes from 'prop-types';
import Footer from "./footer/Footer";
import Menu from "./menu/Menu";
import Underlay from "./underlay"
import styled from "styled-components";
import {useVerticalScroll} from "../hooks/useScrolling";
import MetaTags from "./MetaTags";

const Layout = ({snap, helmet, metatags, underlay, children}) => {
  const { containerRef, itemsRef } = useVerticalScroll(snap);
  const [menu, setMenu] = useState(false);
  const handleMenu = () => {
    setMenu(!menu);
  };

  const addToRefs = el => {
    if (el && !itemsRef.current.includes(el)) {
      itemsRef.current.push(el);
    }
  };

  return (
    <Wrapper id="Layout">
      {metatags ? <MetaTags {...metatags} /> : null}
      {helmet ? helmet : null}
      <Menu menu={menu} handleMenu={handleMenu} />
      <VerticalScroller ref={containerRef}>
        {children.length ? children.map((child, index) => {
          if (child.length) {
            // If we map a return in Layout they will be grandchildren.
            return child.map((grandchild, gc) => {
              return React.cloneElement(grandchild, {key: gc, ref: snap ? addToRefs : null, menu: menu, handleMenu: handleMenu});
            })
          } else {
            return React.cloneElement(child, {key: index, ref: snap ? addToRefs : null, menu: menu, handleMenu: handleMenu});
          }
        }) : () => {
          return React.cloneElement(children, {menu: menu, handleMenu: handleMenu});
        }}
      </VerticalScroller>
      <Footer />
      {underlay ? (
        <Underlay>
          {underlay}
        </Underlay>
      ) : null}
    </Wrapper>
  );
};

export default Layout;

Layout.propTypes = {
}

Layout.defaultProps = {
}

const Wrapper = styled.div`
  position: relative;
`;

const VerticalScroller = styled.div``;

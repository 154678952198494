import React, {useState} from "react";
import {Row, Col} from "antd";
import {Logo} from "../Logo";
import {Link} from "gatsby";
import styled from "styled-components";
import Section from "../section";
import {CONTAINER, H5, LEADTEXT, PARAGRAPH} from "../../styles/Global";
import {BREAKPOINTS, PATHS, SOCIAL} from "../../constants";
import {TextField} from "../../styles/Forms";
import {AiFillFacebook} from "@react-icons/all-files/ai/AiFillFacebook";
import {AiFillInstagram} from "@react-icons/all-files/ai/AiFillInstagram";
import {AiFillTwitterSquare} from "@react-icons/all-files/ai/AiFillTwitterSquare";
import {RiPatreonFill} from "@react-icons/all-files/ri/RiPatreonFill";

const Footer = () => {
  const [userEmail, setUserEmail] = useState("");
  const url = `${process.env.GATSBY_API_URL}/webform_rest/submit`;

  const submitForm = async (event) => {
    event.preventDefault();
    const contactDetail = {
      webform_id: process.env.GATSBY_NEWSLETTER_FORM_ID,
      email: userEmail,
    };

    const result = await fetch(url, {
      body: JSON.stringify(contactDetail),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });

    if (!result.ok) {
      // setSuccessMessage('');
      console.log("Something went wrong!");
    } else {
      // setSuccessMessage('Mesajınız alındı. Teşekkür ederiz.');
      console.log("Message Submitted Successfully");
      setUserEmail("");
    }
  };
  return (
    <Wrapper>
      <Container>
        <Content>
        <TopRow justify="space-between" gutter={{xs: 8, sm: 20, md: 20, lg: 32}}>
          <Col xs={24} sm={12} md={12} lg={12} xl={10}>
            <LogoWrapper><Logo /></LogoWrapper>
            <Paragraph>
              JLTech, LLC. is a marketing agency based in Post Falls, ID,
              providing marketing, media, IT and aerial services. It's our
              goal to help businesses grow through intelligent marketing and
              quality content.
            </Paragraph>
          </Col>
          <Col xs={22} sm={12} md={12} lg={12} xl={10}>
            <TitleWrapper>
              <Title>
                Stay Up To Date
              </Title>
              <SocialMedia>
                <SocialIcon href={SOCIAL.facebook} target="_blank">
                  <AiFillFacebook />
                </SocialIcon>
                <SocialIcon href={SOCIAL.instagram} target="_blank">
                  <AiFillInstagram />
                </SocialIcon>
                <SocialIcon href={SOCIAL.twitter} target="_blank">
                  <AiFillTwitterSquare />
                </SocialIcon>
                <SocialIcon href={SOCIAL.patreon} target="_blank">
                  <RiPatreonFill />
                </SocialIcon>
              </SocialMedia>
            </TitleWrapper>
            <form onSubmit={submitForm}>
              <TextField
                placeholder="Email"
                bordered={false}
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </form>
          </Col>
        </TopRow>
        <Row justify="space-between" gutter={{xs: 8, sm: 20, md: 20, lg: 32}}>
          <Col xs={12} sm={12} md={12} lg={4} xl={3}>
            <MenuTitle>
              JLTech
            </MenuTitle>
            <Menu>
              <MenuItem>
                <MenuItemLink to={PATHS.about}>About Us</MenuItemLink>
              </MenuItem>
              <MenuItem>
                <MenuItemLink to={PATHS.contact}>Contact Us</MenuItemLink>
              </MenuItem>
              <MenuItem>
                <MenuItemLink to={PATHS.blog}>Blog</MenuItemLink>
              </MenuItem>
            </Menu>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={3}>
            <MenuTitle><MenuTitleLink to={PATHS.marketing}>Marketing</MenuTitleLink></MenuTitle>
            <Menu>
              <MenuItem>
                <MenuItemLink to={PATHS.development}>Development</MenuItemLink>
              </MenuItem>
              <MenuItem>
                <MenuItemLink to={PATHS.design}>Design</MenuItemLink>
              </MenuItem>
              <MenuItem>
                <MenuItemLink to={PATHS.sem}>SEM</MenuItemLink>
              </MenuItem>
              <MenuItem>
                <MenuItemLink to={PATHS.drupal}>Drupal</MenuItemLink>
              </MenuItem>
            </Menu>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={3}>
            <MenuTitle><MenuTitleLink to={PATHS.media}>Media</MenuTitleLink></MenuTitle>
            <Menu>
              <MenuItem>
                <MenuItemLink to={PATHS.photography}>Photography</MenuItemLink>
              </MenuItem>
              <MenuItem>
                <MenuItemLink to={PATHS.video}>Videography</MenuItemLink>
              </MenuItem>
              <MenuItem>
                <MenuItemLink to={PATHS.social}>Social Media</MenuItemLink>
              </MenuItem>
              <MenuItem>
                <MenuItemLink to={PATHS.hulu}>Hulu Ads</MenuItemLink>
              </MenuItem>
            </Menu>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={3}>
            <MenuTitle><MenuTitleLink to={PATHS.aerial}>Aerial</MenuTitleLink></MenuTitle>
            <Menu>
              <MenuItem>
                <MenuItemLink to={PATHS.aerialPhotography}>Cinema</MenuItemLink>
              </MenuItem>
              <MenuItem>
                <MenuItemLink to={PATHS.inspections}>Inspection</MenuItemLink>
              </MenuItem>
              <MenuItem>
                <MenuItemLink to={PATHS.firstResponders}>First Responder</MenuItemLink>
              </MenuItem>
              <MenuItem>
                <MenuItemLink to={PATHS.realEstate}>Real Estate</MenuItemLink>
              </MenuItem>
            </Menu>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={3}>
            <MenuTitle><MenuTitleLink to={PATHS.it}>IT</MenuTitleLink></MenuTitle>
            <Menu>
              <MenuItem>
                <MenuItemLink to={PATHS.commercialIT}>Commercial</MenuItemLink>
              </MenuItem>
              <MenuItem>
                <MenuItemLink to={PATHS.residentialIT}>Residential</MenuItemLink>
              </MenuItem>
              <MenuItem>
                <MenuItemLink to={PATHS.security}>Security</MenuItemLink>
              </MenuItem>
            </Menu>
          </Col>
          <Col xs={24} sm={24} md={12} lg={4} xl={9}>
            <Legal>
              <Copyright>
                © Copyright JLTech, LLC. {new Date().getFullYear()}
              </Copyright>
              <div>
                <PrivacyPolicy to={PATHS.legal}>Legal</PrivacyPolicy>
              </div>
            </Legal>
          </Col>
        </Row>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled(Section)`
  background: rgba(26, 18, 88, 1);
  height: auto;
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

const Content = styled.div`
  width: 100%;
  padding: 50px 0 100px;
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    padding: 100px 0;
  }
`;

const TopRow = styled(Row)`
  margin-bottom: 30px;
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    margin-bottom: 100px;
  }
`;

const LogoWrapper = styled.div`
  max-width: 134px;
  margin-bottom: 30px;
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    max-width: 5vw;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between
`;

const Title = styled.h2`
  ${H5}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 30px;
`;

const MenuTitle = styled.h3`
  ${PARAGRAPH}
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
`;

const Menu = styled.ul`
  list-style: none;
  padding-bottom: 10px;
`;

const MenuItem = styled.li`
  margin-bottom: 10px;
`;

const MenuTitleLink = styled(Link)`
  ${PARAGRAPH}
  color: rgba(255, 255, 255, 1);
`;

const MenuItemLink = styled(Link)`
  ${PARAGRAPH}
  color: rgba(241, 240, 245, .5);
`;

const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const SocialIcon = styled.a`
  line-height: 20px;
  font-size: 20px;
  padding: 2px;
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    margin-top: -15px;
    line-height: 50px;
    font-size: 50px;
    padding: 5px;
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    margin-top: 5px;
  }
`;

const Legal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Copyright = styled.div`
  ${PARAGRAPH}
  color: #fff;
  margin-bottom: 10px;
`;

const PrivacyPolicy = styled(Link)`
  ${PARAGRAPH}
  color: rgba(241, 240, 245, .5);
`;

const TermsOfService = styled(Link)`
  ${PARAGRAPH}
  color: rgba(241, 240, 245, .5);
  margin-left: 30px;
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;

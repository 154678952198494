import React, {forwardRef} from "react";
import styled from "styled-components";

const Section = ({children, ...props}, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      {children}
    </Wrapper>
  );
};

export default forwardRef(Section);

const Wrapper = styled.section`
  background: transparent;
  width: 100vw;
  min-width: 100%;
  height: 100vh;
`
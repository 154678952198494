import React from "react";

export const Logo = ({color, ...props}) => {
  let fill = color ? color : '#ffffff';
  return (
    <svg id="Logo" {...props} viewBox="0 0 734.12 100.82" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none meet">
      <defs>
        <linearGradient id="logo-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor={fill}/>
          <stop offset="50%" stopColor={fill}/>
          <stop offset="100%" stopColor={fill}/>
        </linearGradient>
      </defs>
      <g id="LogoInner">
        <polygon id="LogoH" fill="url(#logo-gradient)" points="718.42 0.12 718.42 32.14 661.23 32.1 661.23 0.12 645.54 0.12 645.54 32.08 645.54 44.02 645.54 48.49 645.54 81 661.23 81 661.23 48.44 718.42 48.44 718.42 81 734.12 81 734.12 43.93 734.12 32.16 734.12 0.12 718.42 0.12" />
        <path id="LogoC" fill="url(#logo-gradient)" d="M536.84,65.1V16.33h53.79A22.34,22.34,0,0,0,607,.12H521.08V81.05h72.39a22.37,22.37,0,0,0,13.82-16Z" />
        <path id="LogoE1" fill="url(#logo-gradient)" d="M304.47,16.23h60.37A22.3,22.3,0,0,0,381.22,0H288.09a22.3,22.3,0,0,0,16.38,16.23" />
        <path id="LogoE2" fill="url(#logo-gradient)" d="M304.47,48.23h60.37A22.33,22.33,0,0,0,381.22,32H288.09a22.33,22.33,0,0,0,16.38,16.21" />
        <path id="LogoE3" fill="url(#logo-gradient)" d="M304.47,81.08h60.37A22.34,22.34,0,0,0,381.22,65H288.09a22.34,22.34,0,0,0,16.38,16.21" />
        <path id="LogoT" fill="url(#logo-gradient)" d="M153.53.32a22.54,22.54,0,0,0,16.37,16.1h25.42l1.3.52V81h15.87V17l1.45-.51h25.42A22.84,22.84,0,0,0,255.66.32V.17H153.53V.32" />
        <path id="LogoL" fill="url(#logo-gradient)" d="M134.57,64.77H109.15l-1.45-.51V.08H91.82v81h59.05v-.15a22.9,22.9,0,0,0-16.3-16.16" />
        <path id="LogoJ" fill="url(#logo-gradient)" d="M16.3,64.72H41.72l1.45-.51V.17H59V81H0v-.15A22.84,22.84,0,0,1,16.3,64.72" />
        <path id="LogoDot" fill="url(#logo-gradient)" d="M449.66,57.41l-42.08,42,21.05.92q21,.93,42.06,0l21.05-.92Z" />
      </g>
    </svg>
  );
}
import styled from "styled-components";
import {Input} from "antd";
import {PARAGRAPH} from "./Global";

const { TextArea } = Input;

export const TextAreaSubmit = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const FormSubmit = styled.button`
  writing-mode: vertical-rl;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff3d00;
  border: none;
  color: #ffff;
  box-sizing: border-box;
  text-decoration: none;
  margin-right: 0px;
  font: normal normal normal 22px/30px var(--unnamed-font-family-brandon-grotesque);
  padding: 10px;
  cursor: pointer;
  
  &&:hover,
  &&:focus {
    background-color: rgba(65, 45, 219, 1);
    color: #ffff;
  }
`;

export const MessageField = styled(TextArea)`
  ${PARAGRAPH}
  color: ${props => props.dark ? 'rgba(65, 45, 219, 1)' : '#ffffff'};
  padding-left: 0px;
  padding-bottom: 10px;
  padding-right: 0px;
  width: 100%;
  border-bottom: 1px solid #ff3d00;
  font: normal normal normal 20px/28px var(--unnamed-font-family-brandon-grotesque);
  
  &&::placeholder {
    color: ${props => props.dark ? 'rgba(65, 45, 219, 1)' : '#ffffff'};
  }
  
  &&:hover,
  &&:focus {
    border-bottom: 1px solid #ff3d00;
  }
`;

export const TextField = styled(Input)`
  ${PARAGRAPH}
  color: ${props => props.dark ? 'rgba(65, 45, 219, 1)' : '#ffffff'};
  padding-left: 0px;
  padding-bottom: 10px;
  padding-right: 0px;
  width: 100%;
  border-bottom: 1px solid #ff3d00;
  margin-bottom: 10px;
  font: normal normal normal 20px/28px var(--unnamed-font-family-brandon-grotesque);
  
  &&::placeholder {
    color: ${props => props.dark ? 'rgba(65, 45, 219, 1)' : '#ffffff'};
  }
  
  &&:hover,
  &&:focus {
    border-bottom: 1px solid #ff3d00;
  }
`;
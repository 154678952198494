import React, {useState} from "react";
import {Link, navigate} from "gatsby";
import {BREAKPOINTS, PATHS} from "../../constants";
import styled, {keyframes, css} from "styled-components";
import menuTab from "../../images/menu_tab.svg";
import menuTabBlue from "../../images/menu_tab_blue.svg";
import {LEADTEXT, PARAGRAPH} from "../../styles/Global";
import {FiArrowLeftCircle} from "@react-icons/all-files/fi/FiArrowLeftCircle";
import { v4 as uuidv4 } from 'uuid';

const navigation = [
  {
    id: uuidv4(),
    label: 'home',
    path: '/'
  },
  {
    id: uuidv4(),
    label: 'services',
    path: PATHS.services,
    children: [
      {
        id: uuidv4(),
        label: 'marketing',
        path: PATHS.marketing,
        // children: [
        //   {
        //     id: uuidv4(),
        //     label: 'development',
        //     path: PATHS.development
        //   },
        //   {
        //     id: uuidv4(),
        //     label: 'design',
        //     path: PATHS.design
        //   },
        //   {
        //     id: uuidv4(),
        //     label: 'sem',
        //     path: PATHS.sem
        //   },
        //   {
        //     id: uuidv4(),
        //     label: 'drupal',
        //     path: PATHS.drupal
        //   }
        // ],
      },
      {
        id: uuidv4(),
        label: 'media',
        path: PATHS.media,
        // children: [
        //   {
        //     id: uuidv4(),
        //     label: 'photography',
        //     path: PATHS.photography
        //   },
        //   {
        //     id: uuidv4(),
        //     label: 'video production',
        //     path: PATHS.video
        //   },
        //   {
        //     id: uuidv4(),
        //     label: 'social',
        //     path: PATHS.social
        //   },
        //   {
        //     id: uuidv4(),
        //     label: 'hulu ads',
        //     path: PATHS.hulu
        //   }
        // ],
      },
      {
        id: uuidv4(),
        label: 'IT',
        path: PATHS.it,
        // children: [
        //   {
        //     id: uuidv4(),
        //     label: 'smart homes',
        //     path: PATHS.smartHomes
        //   },
        //   {
        //     id: uuidv4(),
        //     label: 'security',
        //     path: PATHS.security
        //   },
        // ],
      },
      {
        id: uuidv4(),
        label: 'aerial',
        path: PATHS.aerial,
        // children: [
        //   {
        //     id: uuidv4(),
        //     label: 'inspections',
        //     path: PATHS.inspections
        //   },
        //   {
        //     id: uuidv4(),
        //     label: 'real estate',
        //     path: PATHS.inspections
        //   },
        //   {
        //     id: uuidv4(),
        //     label: 'filming',
        //     path: PATHS.aerialPhotography
        //   },
        // ],
      }
    ],
  },
  {
    id: uuidv4(),
    label: 'blog',
    path: PATHS.blog
  },
  {
    id: uuidv4(),
    label: 'about us',
    path: PATHS.about
  },
  {
    id: uuidv4(),
    label: 'contact',
    path: PATHS.contact
  },
];

const MenuItems = ({handleClick, ...props}) => {
  return (
    <>
      {props.id ? (
        <MenuParent>
          <MenuBack to="#" onClick={(e) => handleClick(e, props)}><FiArrowLeftCircle /></MenuBack>
          <MenuLabel to={props.path}>{props.label}</MenuLabel>
        </MenuParent>
      ) : null}
      <MainMenu count={props.children.length}>
        {props.children.map((item, index) => {
          return (
            <MainMenuItem key={index}>
              <MainMenuItemLink to={item.path} onClick={(e) => handleClick(e, item)}>{item.label}</MainMenuItemLink>
            </MainMenuItem>
          );
        })}
      </MainMenu>
    </>
  );
}

const Menu = ({menu, handleMenu, ...props}) => {
  const [state, setState] = useState({
    children: navigation
  });

  // This grabs the parent so we can use that when going back up the menu array.
  const getParent = (children, id) => {
    const iter = (object) => {
      let result;
      (object.children || []).some(o => result = o.id === id ? object : iter(o));
      return result;
    }
    return iter({ children });
  }

  const handleClick = (e, item) => {
    e.preventDefault();

    if (item.children) {
      if (state.id === item.id) {
        const parent = getParent(navigation, item.id);
        if (parent && parent.children) {
          setState(parent);
        } else {
          setState({children: navigation});
        }
      } else {
        setState(item);
      }
    } else {
      navigate(item.path);
    }
  }

  return (
    <Wrapper open={menu}>
      <MenuButtonWrapper>
        <MenuButton open={menu} onClick={() => handleMenu()} />
      </MenuButtonWrapper>
      <Navigation open={menu}>
        <MenuItems {...state} handleClick={handleClick} />
      </Navigation>
    </Wrapper>
  );
};

export default Menu;

const Wrapper = styled.div`
  max-height: 100%;
  max-width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border: ${props => props.open ? '20px solid #1a1258' : '0px solid #FF3D00'};
  z-index: 1000;
  position: fixed;
  transition: border-width 0.25s linear;
  pointer-events: none;
`;

const MenuButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const MenuButton = styled.button`
  border: 0;
  padding: 0;
  width: 80px;
  height: 19px;
  cursor: pointer;
  background-image: url("${menuTab}");
  background-size: contain;
  background-color: transparent;
  background-repeat: no-repeat;
  pointer-events: all;
  
  &&:hover {
    background-image: url("${menuTabBlue}");
  }
  
  ${props => props.open ? `background-image: url("${menuTabBlue}")` : ''}
`;

const slideIn = keyframes`
  0% { transform: translateY(100%); }
  100% { transform: translateY(0%); }
`;

const slideLeft = keyframes`
  0% { margin-left: 100px; }
  100% { margin-left: 0px; }
`;

const MenuListCSS = (count) => {
  let styles = '';

  for (let i = 1; i <= count; i++) {
    styles += `
      &&:nth-child(${i}) {
        animation-delay: ${i * 1}s;
      }
    `
  }
  return css`${styles}`;
}

const Navigation = styled.div`
  max-width: 100%;
  width: 100%;
  background-color: ${props => props.open ? '#1a1258' : '#FF3D00'};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: block;
  transform: translateY(100%);
  pointer-events: all;
  
  ${props => props.open ? css`
    animation: ${slideIn} 0.5s forwards;
  ` : ''}
  
  @media (min-width: ${BREAKPOINTS['sm']}px) {
    ${props => props.open ? css`
      ul li {
        margin-left: 100px;
        animation: ${slideLeft} 0.5s forwards;
        animation-delay: 0.5s;
        ${MenuListCSS(`${props => props.count}`)}
      }
    ` : ''}
  }
`;

const MenuParent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  
  [aria-current="page"] {
    color: #FF3D00;
  }
  
  @media (min-width: ${BREAKPOINTS['sm']}px) {
    height: 80px;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    height: 120px;
  }
`;

const MenuBack = styled(Link)`
  ${LEADTEXT}
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  color: #f1f0f5;
  font: normal normal normal 20px/27px var(--unnamed-font-family-proxima-nova);
  white-space: nowrap;
  padding: 20px 0 20px 20px;
  position: absolute;
  left: 0;
  top: 0;
  
  @media (min-width: ${BREAKPOINTS['sm']}px) {
    position: relative;
  }
`;

const MenuLabel = styled(Link)`
  ${PARAGRAPH}
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  color: #f1f0f5;
  font: normal normal normal 20px/27px var(--unnamed-font-family-proxima-nova);
  white-space: nowrap;
  padding: 20px;
  
  @media (min-width: ${BREAKPOINTS['sm']}px) {
    padding: 20px 20px 20px 5px;
  }
`;

const MainMenu = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  list-style: none;
  
  @media (min-width: ${BREAKPOINTS['sm']}px) {
    flex-direction: row;
    height: 80px;
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    height: 120px;
  }
`;

const MainMenuItem = styled.li`
  list-style: none;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  list-style: none;
  
  [aria-current="page"] {
    color: #FF3D00;
  }
  
  @media (min-width: ${BREAKPOINTS['sm']}px) {
    flex-direction: row;
    height: 80px;
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    height: 120px;
  }
`;

const MainMenuItemLink = styled(Link)`
  ${PARAGRAPH}
  text-decoration: none;
  color: #f1f0f5;
  font: normal normal normal 20px/27px var(--unnamed-font-family-proxima-nova);
  white-space: nowrap
`;

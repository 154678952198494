import {useRef, useEffect, useState} from "react";
import { gsap, ScrollToPlugin } from "gsap/all";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const onEnterSectionAnimation = (item) => {
  let target = `${item.id ? `#${item.id} ` : ''}.animate-in-slide-up`;
  if(document.querySelector(target)) {
    gsap.fromTo(target, {y: (item.clientHeight / 4), opacity: 0}, {
      y: 0,
      opacity: 1,
      duration: 1,
      ease: "ease",
      stagger: 0.1
    });
  }
  target = `${item.id ? `#${item.id} ` : ''}.animate-in-slide-down`;
  if(document.querySelector(target)) {
    gsap.fromTo(target, {
      y: -(item.clientHeight / 4),
      opacity: 0
    }, {y: 0, opacity: 1, duration: 1, ease: "ease", stagger: 0.1});
  }
  target = `${item.id ? `#${item.id} ` : ''}.animate-in-slide-left`;
  if(document.querySelector(target)) {
    gsap.fromTo(target, {
      x: (item.clientHeight / 4),
      opacity: 0
    }, {x: 0, opacity: 1, duration: 1, ease: "ease", stagger: 0.1});
  }
  target = `${item.id ? `#${item.id} ` : ''}.animate-in-slide-right`;
  if(document.querySelector(target)) {
    gsap.fromTo(target, {
      x: -(item.clientHeight / 4),
      opacity: 0
    }, {x: 0, opacity: 1, duration: 1, ease: "ease", stagger: 0.1});
  }
  target = `${item.id ? `#${item.id} ` : ''}.animate-in-zoom-in`;
  if(document.querySelector(target)) {
    gsap.fromTo(target, {scaleX: -0.1, scaleY: -0.1}, {
      scaleX: 1,
      scaleY: 1,
      duration: 1,
      ease: "ease",
      stagger: 0.2
    });
  }
  target = `${item.id ? `#${item.id} ` : ''}.animate-in-zoom-out`;
  if(document.querySelector(target)) {
    gsap.fromTo(target, {scaleX: 2, scaleY: 2}, {
      scaleX: 1,
      scaleY: 1,
      duration: 1,
      ease: "ease",
      stagger: 0.2
    });
  }
  target = `${item.id ? `#${item.id} ` : ''}.animate-in-shine`;
  if(document.querySelector(target)) {
    gsap.fromTo(target,{
      boxShadow: '0px 0px 300px 0px rgba(255, 255, 255, 1)'
    },{
      boxShadow: '0px 0px 100px 0px rgba(255, 255, 255, .2)'
    });
  }
  target = `${item.id ? `#${item.id} ` : ''}form`;
  if(document.querySelector(target)) {
    gsap.fromTo(target, {scaleX: 1.5, scaleY: 1.5}, {
      scaleX: 1,
      scaleY: 1,
      duration: 1,
      ease: "ease",
      stagger: 0.2
    });
  }
  target = `${item.id ? `#${item.id} ` : ''}button[type='submit']`;
  if(document.querySelector(target)) {
    gsap.fromTo(target, {scaleX: 2, scaleY: 2}, {
      scaleX: 1,
      scaleY: 1,
      duration: 1,
      ease: "ease",
      stagger: 0.2
    });
  }
  target = `${item.id ? `#${item.id} ` : ''}.animate-in-background`;
  if(document.querySelector(target)) {
    gsap.to(target, {
      opacity: 1,
      duration: 3,
      delay: 0.5,
      ease: "power3",
      onStart: () => {
        //console.log("STARTING!", item)
      }
    });
  }
  target = `${item.id ? `#${item.id} ` : ''}.benefits-items`;
  if(document.querySelector(target)) {
    gsap.fromTo(target, {
      scrollTo: {
        y: 3000,
      }, duration: 0
    }, {
      scrollTo: {
        y: 0,
      }, duration: 2
    });
  }
}

const onLeaveSectionAnimation = (item) => {
  let target = `${item.id ? `#${item.id} ` : ''}.animate-out-slide-up`;
  if(document.querySelector(target)) {
    gsap.fromTo(target, {
      y: 0,
      opacity: 1
    }, {y: (item.clientHeight / 4), opacity: 0, duration: 1, ease: "ease", stagger: 0.1});
  }
  target = `${item.id ? `#${item.id} ` : ''}.animate-out-slide-down`;
  if(document.querySelector(target)) {
    gsap.fromTo(target, {
      y: 0,
      opacity: 1
    }, {y: -(item.clientHeight / 4), opacity: 0, duration: 1, ease: "ease", stagger: 0.1});
  }
  target = `${item.id ? `#${item.id} ` : ''}.animate-out-slide-left`;
  if(document.querySelector(target)) {
    gsap.fromTo(target, {
      x: 0,
      opacity: 1
    }, {x: (item.clientHeight / 4), opacity: 0, duration: 1, ease: "ease", stagger: 0.1});
  }
  target = `${item.id ? `#${item.id} ` : ''}.animate-out-slide-right`;
  if(document.querySelector(target)) {
    gsap.fromTo(target, {
      x: 0,
      opacity: 1
    }, {x: -(item.clientHeight / 4), opacity: 0, duration: 1, ease: "ease", stagger: 0.1});
  }
  target = `${item.id ? `#${item.id} ` : ''}.animate-out-zoom-in`;
  if(document.querySelector(target)) {
    gsap.fromTo(target, {scaleX: 1, scaleY: 1}, {
      scaleX: -0.1,
      scaleY: -0.1,
      duration: 1,
      ease: "ease",
      stagger: 0.2
    });
  }
  target = `${item.id ? `#${item.id} ` : ''}.animate-out-zoom-out`;
  if(document.querySelector(target)) {
    gsap.fromTo(target, {scaleX: 1, scaleY: 1}, {
      scaleX: 2,
      scaleY: 2,
      duration: 1,
      ease: "ease",
      stagger: 0.2
    });
  }
}

export const useVerticalScroll = (snap) => {
  const [state, setState] = useState({top: false});

  const containerRef = useRef(null);
  const itemsRef = useRef([]);

  let snapStart = 0.1;
  let snapDuration = 0.35;

  useEffect(() => {
    gsap.to(window, {
      duration: 0,
      scrollTo: {y: 0, offsetY: 0},
      onComplete: () => {
        setState({...state, ...{top: true}});
      }
    });
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    const items = itemsRef.current;

    if (snap && state.top && container && items.length) {
      ScrollTrigger.matchMedia({
        // desktop
        "(min-width: 1200px)": () => {
          const goToSection = (i, offset, anim) => {
            gsap.to(window, {
              scrollTo: {
                y: offset,
                autoKill: false,
                ease: "power3"
              },
              duration: snapDuration,
            });

            if(anim) {
              anim.restart();
            }
          }

          let clientHeight = window.innerHeight;
          let scrollx = {};
          let horizontal = false;
          items.forEach((item, i) => {
            const prevItem = item.previousElementSibling;

            if (item.attributes["data-scrollx"]) {
              scrollx = items[i];
            }

            if (prevItem && prevItem.attributes["data-scrollx"]) {
              horizontal = true;
            }

            ScrollTrigger.create({
              id: `scroll-x-section-${i}`,
              trigger: items[i],
              toggleClass: !item.attributes["data-scrollx"] && {targets: items[i], className: "active-section"},
              invalidateOnRefresh: true,
              refreshPriority: 100,
              start: () => {
                let start = (items[i].offsetTop - (clientHeight * (1 - snapStart)));

                if (horizontal) {
                  start = `${scrollx.scrollWidth - items[i].clientWidth - clientHeight + (clientHeight * snapStart)}`;
                } else if (item.attributes["data-scrollx"]) {
                  start = `top bottom-=${(clientHeight * snapStart)}`;
                }

                return start;
              },
              end: () => {
                let end = `${items[i].clientHeight + (clientHeight * snapStart)}`;
                if (horizontal) {
                  end = `${scrollx.scrollWidth - items[i].clientWidth + (clientHeight * snapStart)}`;
                } else if (item.attributes["data-scrollx"]) {
                  end = `${scrollx.scrollWidth - items[i].clientWidth + (clientHeight * snapStart)}`;
                }
                return end;
              },
              onEnter: (props) => {
                //console.log(`ENTERING ${i}: `, props, item);
                let start = props.start;
                start += -(clientHeight * snapStart);

                if (snap) {
                  goToSection(i, start + clientHeight);
                }

                if (item.attributes["data-scrollx"]) {
                  const inner = item.childNodes[0];
                  gsap.timeline({
                    scrollTrigger: {
                      id: `scroll-x-section-${i}-enter`,
                      trigger: inner,
                      pin: items[i],
                      scrub: 1,
                      refreshPriority: 100,
                      pinSpacing: "margin",
                      anticipatePin: 1,
                      end: () => `+=${inner.scrollWidth - items[i].clientWidth}`,
                      invalidateOnRefresh: true,
                    }
                  })
                    .to(inner, {
                      x: () => `-${inner.scrollWidth - items[i].clientWidth}px`,
                      ease: 'none',
                  });
                } else {
                  onEnterSectionAnimation(item);
                }

              },
              onLeave: (props) => {
                //console.log(`LEAVING ${i}: `, props, item);
              }
            }
            );

            // Handle scroll snap for all the horizontal scrolling.
            if (item.attributes["data-scrollx"]) {
              let enterOffset = 0;
              let reEnterOffset = 0;
              let children = item.childNodes[0].childNodes;

              children.forEach((child, c) => {
                let prevIndex = c ? c-1 : c;
                let nextIndex = c !== children.length - 1 ? c+1 : c;
                if (c > 1) {
                  enterOffset = enterOffset + children[prevIndex].clientWidth;
                }

                reEnterOffset = reEnterOffset + children[c].clientWidth;

                //console.log(child, enterOffset);

                ScrollTrigger.create({
                  id: `scroll-x-section-${i}-child-${c}-enter`,
                  trigger: items[i],
                  scrub: 1,
                  refreshPriority: 100,
                  toggleClass: {targets: `#${child.id}`, className: "active-section"},
                  invalidateOnRefresh: true,
                  start: () => {
                    if (c) {
                      return `+=${enterOffset + (items[i].clientHeight * snapStart)}`;
                    } else {
                      return `+=${enterOffset - (items[i].clientHeight * snapStart)}`;
                    }
                  },
                  end: () => {
                    return `+=${enterOffset + children[c].clientWidth}`;
                  },
                  onEnter: ({start, ...props}) => {
                    //console.log(`ENTERING CHILD ${c}: `, child);
                    onEnterSectionAnimation(child);

                    if (c) {
                      let scrollTo = start + children[prevIndex].clientWidth - (items[i].clientHeight * snapStart);

                      gsap.to(window, {
                        scrollTo: {
                          y: scrollTo,
                          autoKill: false,
                          ease: "power3"
                        },
                        duration: snapDuration,
                      });
                    }
                  },
                  onEnterBack: () => {
                    //console.log(`ENTERING BACK CHILD ${c}: `, child);
                    onEnterSectionAnimation(child);
                  },
                  onLeave: () => {
                    //console.log(`LEAVING CHILD ${c}: `, child);
                    onLeaveSectionAnimation(child);
                  },
                  onLeaveBack: () => {
                    //console.log(`LEAVING BACK CHILD ${c}: `, child);
                    onLeaveSectionAnimation(child);
                  }
                });

                ScrollTrigger.create({
                  id: `scroll-x-section-${i}-child-${c}-enter-back`,
                  trigger: items[i],
                  scrub: 1,
                  refreshPriority: 100,
                  invalidateOnRefresh: true,
                  start: () => {
                    return `+=${reEnterOffset - (items[i].clientHeight * snapStart)}`;
                  },
                  onEnterBack: ({start, ...props}) => {
                    //console.log(`REENTERING CHILD ${c}: `, child);

                    onEnterSectionAnimation(item);

                    if (c !== children.length - 1) {
                      let scrollTo = start - children[c].clientWidth + (items[i].clientHeight * snapStart);

                      gsap.to(window, {
                        scrollTo: {
                          y: scrollTo,
                          autoKill: false,
                          ease: "power3"
                        },
                        duration: snapDuration,
                      });
                    }
                  },
                });

              })
            }

            ScrollTrigger.create({
              id: `scroll-x-section-${i}-enter-back`,
              trigger: items[i],
              refreshPriority: 100,
              invalidateOnRefresh: true,
              start: () => {
                if (horizontal) {
                  return `+=${Math.ceil(scrollx.scrollWidth - scrollx.clientWidth - (items[i].clientHeight * snapStart))}`;
                } else if (items[i].attributes["data-scrollx"]) {
                  return `+=${Math.ceil(scrollx.scrollWidth - scrollx.clientWidth - (items[i].clientHeight * snapStart))}`;
                }
                return `-=${items[i].clientHeight * snapStart}`;
              },
              end: () => {
                return `+=${items[i].clientHeight}`;
              },
              onEnterBack: (props) => {
                // console.log(`REENTERING ${i}: `, item);
                let end = props.end + (items[i].clientHeight * snapStart);

                if (snap) {
                  goToSection(i, end - items[i].clientHeight);
                }

                onEnterSectionAnimation(item);
              },
            });

          });
        },

        "(max-width: 1199px)": () => {
          items.forEach((item, i) => {
            ScrollTrigger.create({
              id: "scroll-x-mobile",
              trigger: items[i],
              invalidateOnRefresh: true,
              refreshPriority: 100,
              onEnter: () => {
                //onEnterSectionAnimation(item);
                if (item.attributes["data-scrollx"]) {
                  const inner = item.childNodes[0];
                  gsap.timeline({
                    scrollTrigger: {
                      trigger: inner,
                      pin: items[i],
                      scrub: 1,
                      pinSpacing: "margin",
                      end: () => `+=${inner.scrollWidth - items[i].clientWidth}px`,
                      invalidateOnRefresh: true,
                    }
                  }).to({}, {duration: 0.25})
                    .to(inner, {
                      x: () => `-${inner.scrollWidth - items[i].clientWidth}px`,
                      ease: 'none',
                    })
                    .to({}, {duration: 0.25});
                }
              },
            });
          });
        }
      });
    }

    return () => {
      gsap.to(window, {
        duration: 0,
        scrollTo: {y: 0, offsetY: 0},
      });

      // We kill all scroll triggers so the next pages can recreate them.
      const triggers = ScrollTrigger.getAll();
      if (triggers) {
        triggers.forEach((trigger) => {
          // Only kill triggers for scroll-x
          if (trigger.vars && trigger.vars.id && trigger.vars.id.substr(0, 8) === "scroll-x") {
            trigger.kill();
          }
        })
      }
    };
  }, [state.top]);

  // const [scrollPosition, setScrollPosition] = useState(0);
  // const handleScroll = () => {
  //   const position = window.pageYOffset;
  //   setScrollPosition(position);
  // };
  //
  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll, { passive: true });
  //
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  //console.log(scrollPosition);

  return { containerRef, itemsRef };
}